h1 {
  color: rgb(249 195 89);
  font-family: 'Poppins';
}

span {
  font-family: sans-serif;
  font-weight: 200;
}

hr {
  border: 3px solid rgb(155 31 83);
  border-radius: 4px;
}

.img {
  width: 170px;
  height: 90px;

  border-radius: 150px;
}

.header_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  padding: 1rem 0;
}

.logo {
  position: relative;
  top: -35px;
  width: 250px;
}

.text {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  line-height: 22px;
}

.main_section {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 2rem 0;
  text-align: start;
  margin: 0 auto;
  /* display:flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem; */
}


.content_left {
  width: 60%;
  animation: slideFromLeft 1s ease forwards;
  opacity: 0;
  background: url("../../public/assets/pink_circle.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 10px bottom 90px;
}

.content_left>h1 {
  font-size: 30px;
}

@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.buttun_group {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
}

.PROOF,
.NFT {
  border: none;
  border-radius: 1.5rem;
  background-color: #fff;
  color: black;
  font-size: 14px;
  font-weight: 800;
  width: fit-content;
  padding: .4rem 30px;
  cursor: pointer;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.content_right {
  animation: slideFromRight 1s ease forwards;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../../public/assets/pink_circle.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: right 10px top 40px;

}

@keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}




.image_container {
  width: 40rem;
}

.image_container img {
  width: 100%;
}

.button_group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 60px;
}

.button_group>button {
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 1199px) {
  .header_content h1 {
    font-size: 24px;
  }

  .content_left h1 {
    font-size: 20px;
  }
}

@media (max-width: 1060px) {}

@media (max-width: 991px) {
  .main_section {
    flex-direction: column;
    justify-content: flex-start;
  }

  .header_content {
    flex-direction: column-reverse;
  }

  .logo {
    top: 0;
    align-self: flex-end;
  }

  .content_left {
    width: 100%;
  }

  .content_right {
    width: 100%;
  }

  .image_container {
    width: 50%;
  }

  .button_group {
    margin-top: 15px;
  }

  .content_left {
    background-position: left 200px bottom 90px;
  }
}

@media (max-width: 767px) {
  .header_content h1 {
    font-size: 21px;
  }

  .image_container {
    width: 70%;
  }

  .PROOF,
  .NFT {
    border-radius: 1.5rem;
    font-size: 12px;
    font-weight: 600;
    padding: .2rem 20px;
  }
}

@media (max-width: 575px) {

  .image_container {
    width: 75%;
  }
}